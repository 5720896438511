import React, {useEffect, useState} from 'react';
import {CheckoutButton} from '../../../../../common/components/CheckoutButton/CheckoutButton';
import {useControllerProps} from '../../../../../domain/controllers/ControllerContext';
import s from './CheckoutButtons.scss';
import {ExpressCheckoutButtons} from '@wix/ecom-platform-express-checkout-buttons';
import settingsParams from '../../../settingsParams';
import {useSettingsWithDefaults} from '../../../../../hooks/useSettingsWithDefaults';

export enum CheckoutButtonsDataHook {
  Default = 'CheckoutButtons.default',
  Top = 'CheckoutButtons.topView',
  Wrapper = 'CheckoutButtons.Wrapper',
}

interface CheckoutButtonsProps {
  isOnTop?: boolean;
}

export function CheckoutButtons(props: CheckoutButtonsProps) {
  const {shouldShowDynamicPaymentOptions, dynamicPaymentOptionsProps} = useControllerProps().cashierExpressStore;
  const {estimatedTotals, shouldShowExpressCheckout, isFreeCart, isCardTokenizationCart, isSummaryUpdating} =
    useControllerProps().cartStore;
  const {isCheckoutRegularFlowEnabled} = useControllerProps().paymentMethodStore;
  const {isCheckoutButtonDisabled, isNavigationToCheckoutInProcess} = useControllerProps().navigationStore;
  const {getSettingsWithDefaults} = useSettingsWithDefaults();
  const [isLoaded, setIsLoaded] = useState(false);
  const {isOnTop} = props;
  const dataHook = isOnTop ? CheckoutButtonsDataHook.Top : CheckoutButtonsDataHook.Default;
  const shouldRequestShipping = dynamicPaymentOptionsProps?.shouldRequestShipping ?? false;
  const canCheckout = dynamicPaymentOptionsProps?.canCheckout ?? false;

  const showCheckoutButton = isCheckoutRegularFlowEnabled || isFreeCart;

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  return (
    <div data-hook={dataHook} className={s.checkoutButtons}>
      {showCheckoutButton && (
        <CheckoutButton
          disabled={isCheckoutButtonDisabled || isSummaryUpdating}
          text={getSettingsWithDefaults(settingsParams.CART_BUTTON_TEXT)}
          isNavigationToCheckoutInProcess={isNavigationToCheckoutInProcess}
        />
      )}
      {shouldShowDynamicPaymentOptions && isLoaded && shouldShowExpressCheckout && !isCardTokenizationCart ? (
        <div className={s.expressButtonsContainer}>
          <ExpressCheckoutButtons
            layout={'vertical'}
            isShippable={shouldRequestShipping}
            estimatedTotal={estimatedTotals?.estimatedAmountToPay.toString()}
            canStartPayment={canCheckout}
            theme={dynamicPaymentOptionsProps?.getButtonTheme}
            buttonStyle={dynamicPaymentOptionsProps?.buttonStyle}
            dataHook={CheckoutButtonsDataHook.Wrapper}
          />
        </div>
      ) : null}
    </div>
  );
}
