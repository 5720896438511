import React, {useEffect} from 'react';
import {useTranslation} from '@wix/yoshi-flow-editor';
import {useControllerProps} from '../../../../../domain/controllers/ControllerContext';
import {ButtonPriority} from 'wix-ui-tpa';
import {classes} from './Footer.st.css';
import {CheckoutButton} from '../../../../../common/components/CheckoutButton/CheckoutButton';
import {CartButton} from '../CartButton/CartButton';
import {SecureCheckout} from '../../../../../common/components/SecureCheckout/SecureCheckout';
import {Violations} from '../../../../../common/components/Violations/Violations';
import settingsParams from '../../../settingsParams';
import stylesParams from '../../../stylesParams';
import {Divider, Text} from 'wix-ui-tpa/cssVars';
import {LazyExpressCheckoutButtons} from '../../../../../common/components/LazyExpressCheckoutButtons';
import classNames from 'classnames';
import {useBooleanStyleForAnyBreakpoint} from '../../../../../common/useBooleanStyleForAnyBreakpoint';
import {useSettingsWithDefaults} from '../../../../../hooks/useSettingsWithDefaults';
import {FooterSkeleton} from './FooterSkeleton/FooterSkeleton';
import {SkeletonLine} from '../../../../../common/components/Skeleton/SkeletonLine/SkeletonLine';

export enum FooterDataHooks {
  root = 'Footer.root',
  container = 'Footer.container',
  skeleton = 'Footer.skeleton',
  subtotalContainer = 'Footer.subtotalContainer',
  subtotalLabel = 'Footer.subtotalLabel',
  subtotalValue = 'Footer.subtotalValue',
  subTotalValueSkeleton = 'Footer.subTotalValueSkeleton',
  subtotalDisclaimer = 'Footer.subtotalDisclaimer',
  currencyConverterDisclaimer = 'Footer.currencyConverterDisclaimer',
  expressCheckoutButtons = 'Footer.expressCheckoutButtons',
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export const Footer = () => {
  const {t} = useTranslation();
  const {
    cartModel,
    estimatedTotals,
    shouldRenderEmptyState,
    shouldShowExpressCheckout,
    isCardTokenizationCart,
    sideCartElementsVisibilityInCss,
    pageLoaded,
    isSummaryUpdating,
  } = useControllerProps().cartStore;
  const {shouldShowDynamicPaymentOptions, dynamicPaymentOptionsProps} = useControllerProps().cashierExpressStore;
  const {shouldRequestShipping, canCheckout} = dynamicPaymentOptionsProps || {};
  const {orderStore} = useControllerProps();
  const {isCheckoutButtonDisabled, isNavigationToCheckoutInProcess} = useControllerProps().navigationStore;
  const {getSettingsWithDefaults} = useSettingsWithDefaults();
  const {getBooleanStyle} = useBooleanStyleForAnyBreakpoint();
  useEffect(
    () => {
      pageLoaded();
    },
    /* eslint-disable react-hooks/exhaustive-deps*/ []
  );
  if (shouldRenderEmptyState) {
    return false;
  }
  const {isMainCurrency} = orderStore;
  const shouldShowTotalsDisclaimer = getBooleanStyle(
    stylesParams.CART_SUMMARY_DISCLAIMER_VISIBILITY,
    sideCartElementsVisibilityInCss
  );

  const shouldShowSecureCheckout = getBooleanStyle(
    stylesParams.CART_SUMMARY_SECURE_BADGE_VISIBILITY,
    sideCartElementsVisibilityInCss
  );
  const shouldGoToCartButton = getBooleanStyle(
    stylesParams.CART_ACTION_BUTTONS_GO_TO_CART_VISIBILITY,
    sideCartElementsVisibilityInCss
  );
  const shouldShowCheckoutButton = getBooleanStyle(
    stylesParams.CART_ACTION_BUTTONS_CHECKOUT_VISIBILITY,
    sideCartElementsVisibilityInCss
  );

  const showSkeleton = !estimatedTotals && isSummaryUpdating;

  return (
    <section className={classes.root} data-hook={FooterDataHooks.root}>
      <Divider className={classes.divider} />
      {showSkeleton ? (
        <FooterSkeleton />
      ) : (
        <div data-hook={FooterDataHooks.container} className={classes.container}>
          <div
            className={classes.subtotalRow}
            aria-live="polite"
            role="status"
            data-hook={FooterDataHooks.subtotalContainer}>
            <Text tagName={'h3'} data-hook={FooterDataHooks.subtotalLabel} className={classes.subtotalLabel}>
              {t('sideCart.subtotal.label')}
            </Text>
            {isSummaryUpdating ? (
              <SkeletonLine dataHook={FooterDataHooks.subTotalValueSkeleton} size="small" />
            ) : (
              <Text
                data-hook={FooterDataHooks.subtotalValue}
                className={classes.subtotalValue}
                data-wix-sub-totals-value>
                {cartModel.subtotal?.formattedConvertedAmount}
              </Text>
            )}
          </div>
          <div className={classes.disclaimers}>
            {!isMainCurrency && (
              <Text data-hook={FooterDataHooks.currencyConverterDisclaimer} className={classes.disclaimerText}>
                {t('cart.currency_converter_disclaimer', {mainCurrency: cartModel.currencyCode})}
              </Text>
            )}
            {shouldShowTotalsDisclaimer ? (
              <Text
                data-hook={FooterDataHooks.subtotalDisclaimer}
                className={classNames(
                  classes.disclaimerText,
                  sideCartElementsVisibilityInCss ? classes.disclaimerTextVisibility : undefined
                )}>
                {getSettingsWithDefaults(settingsParams.CART_DISCLAIMER_TEXT)}
              </Text>
            ) : null}
          </div>
          <Violations />
          <div className={classes.buttons}>
            {shouldShowCheckoutButton ? (
              <CheckoutButton
                className={sideCartElementsVisibilityInCss ? classes.checkoutButtonVisibility : undefined}
                priority={ButtonPriority.primary}
                disabled={isCheckoutButtonDisabled}
                text={getSettingsWithDefaults(settingsParams.SIDE_CART_CHECKOUT_BUTTON)}
                isNavigationToCheckoutInProcess={isNavigationToCheckoutInProcess}
              />
            ) : null}
            {shouldGoToCartButton ? (
              <CartButton className={sideCartElementsVisibilityInCss ? classes.cartButtonVisibility : null} />
            ) : null}
            {shouldShowExpressCheckout && shouldShowDynamicPaymentOptions && !isCardTokenizationCart ? (
              <div className={classes.expressCheckoutButtonsVisibility}>
                <LazyExpressCheckoutButtons
                  layout={'vertical'}
                  isShippable={shouldRequestShipping}
                  estimatedTotal={estimatedTotals?.estimatedAmountToPay.toString()}
                  canStartPayment={canCheckout}
                  dataHook={FooterDataHooks.expressCheckoutButtons}
                />
              </div>
            ) : null}
          </div>
          {shouldShowSecureCheckout ? (
            <SecureCheckout
              text={t('sideCart.secureCheckout.badge')}
              className={sideCartElementsVisibilityInCss ? classes.secureCheckoutVisibility : undefined}
            />
          ) : null}
        </div>
      )}
    </section>
  );
};
