import {BIService} from '../services/BIService';
import {CartStore} from '../stores/CartStore';
import {CashierExpressStore} from '../stores/CashierExpressStore';
import {ICartControllerApi, ICartStyleSettings, IControllerProps, VeloInputs} from '../../types/app.types';
import {NavigationStore} from '../stores/NavigationStore';
import {OrderStore} from '../stores/OrderStore';
import {ControllerParams} from '@wix/yoshi-flow-editor';
import {NavigationService} from '../services/NavigationService';
import {StyleSettingsService} from '../services/StyleSettingsService';
import {CartService} from '../services/CartService';
import {ProductsService} from '../services/ProductsService';
import {OrderService} from '../services/OrderService';
import {
  CheckoutNavigationService,
  MultilingualService,
  BaseController,
  EcomPlatformViewerScriptContext,
} from '@wix/wixstores-client-storefront-sdk';
import {StoreMetaDataService} from '../services/StoreMetaDataService';
import {ModalManagerService} from '../services/ModalManagerService';
import {MinimumOrderAmountService} from '../services/MinimumOrderAmountService';
import {APP_DEFINITION_ID} from '@wix/wixstores-client-core';
import {applyDefaults} from '../utils/applyDefaults';
import {PaymentMethodStore} from '../stores/PaymentMethodStore';
import {SPECS} from '../specs';
import {createSlotVeloAPIFactory} from '@wix/widget-plugins-ooi/velo';
import {FedopsInteractions, SlotsMap} from '../../common/constants';

export abstract class BaseCartController extends BaseController {
  protected abstract getOrigin(): string;
  protected abstract getCartLayout(): 'cart' | 'sideCart';
  protected abstract getDefaultSettings(): ICartStyleSettings;
  protected currentCartService: EcomPlatformViewerScriptContext['currentCartService'];

  private readonly services = {} as {
    biService: BIService;
    cartService: CartService;
    navigationService: NavigationService;
    modalManagerService: ModalManagerService;
    orderService: OrderService;
    productsService: ProductsService;
    styleSettingsService: StyleSettingsService;
    checkoutNavigationService: CheckoutNavigationService;
    multilingualService: MultilingualService;
    storeMetaDataService: StoreMetaDataService;
    minimumOrderAmountService: MinimumOrderAmountService;
  };
  private veloInputs: VeloInputs;
  private isControllerLoaded: boolean;
  public stores = {} as {
    navigation: NavigationStore;
    paymentMethod: PaymentMethodStore;
    cart: CartStore;
    order: OrderStore;
    cashierExpress: CashierExpressStore;
  };

  constructor(controllerParams: ControllerParams) {
    super(controllerParams);
    this.flowAPI.fedops.interactionStarted(FedopsInteractions.lineItemsLoaded);
    this.flowAPI.fedops.interactionStarted(FedopsInteractions.padeLoaded);
    this.isControllerLoaded = false;
    this.initDefaultVeloInputs();
    this.currentCartService = (controllerParams.appData.context as EcomPlatformViewerScriptContext).currentCartService;
    this.subscribeToChanges();
    this.setServices(controllerParams);

    if (this.siteStore.experiments.enabled(SPECS.AddSlotToCartPage)) {
      const slotAPIFactory = createSlotVeloAPIFactory(controllerParams.controllerConfig);
      const slot = slotAPIFactory?.getSlotAPI(SlotsMap.mainBottom);
      slot.cartId = 'id';
    }

    this.setStores(controllerParams);
  }

  private initDefaultVeloInputs(): void {
    this.veloInputs = {shouldShowSecureCheckout: true};
  }

  public readonly updateComponent = async () => {
    this.setProps({
      cartStore: await this.stores.cart.toProps(),
      cashierExpressStore: this.stores.cashierExpress.toProps(),
      navigationStore: await this.stores.navigation.toProps(),
      paymentMethodStore: this.stores.paymentMethod.toProps(),
      orderStore: this.stores.order.toProps(),
      isResponsive: this.services.styleSettingsService.isEditorX,
      fitToContentHeight: true,
    } as IControllerProps);
  };

  public setVeloInputs = async (veloInputs: VeloInputs) => {
    this.veloInputs = {
      ...this.veloInputs,
      ...veloInputs,
    };
    this.stores.cart.setOverrides(this.veloInputs);
    if (this.isControllerLoaded) {
      await this.updateComponent();
    }
  };

  /* istanbul ignore next */
  /**
   * @deprecated
   */
  protected readonly reportFedopsInteraction = () => {
    throw new Error('Use reportFedops');
  };

  protected readonly reportFedops = async (interaction: FedopsInteractions, fn?: Function) => {
    this.flowAPI.fedops.interactionStarted(interaction);
    this.flowAPI.panoramaClient?.transaction(interaction).start();
    if (fn) {
      await fn();
    }
    this.flowAPI.fedops.interactionEnded(interaction);
    this.flowAPI.panoramaClient?.transaction(interaction).finish();
  };

  private get controllerApi(): ICartControllerApi {
    return {
      executeWithLoader: this.executeWithLoader,
      updateComponent: this.updateComponent,
      reportFedops: this.reportFedops,
      t: this.t,
    };
  }

  public readonly load = async (): Promise<void> => {
    this.services.cartService.isSummaryUpdating = true;
    await this.updateComponent();

    await this.services.cartService.fetchCartAndEstimateTotals();
    await this.updateComponent();
  };

  public readonly init = async (): Promise<void> => {
    void this.stores.paymentMethod.init().then(() => {
      /* istanbul ignore next */
      if (this.isControllerLoaded) {
        this.setProps({paymentMethodStore: this.stores.paymentMethod.toProps()});
      }
    });

    await this.services.cartService.fetchCartAndEstimateTotals();
    await this.stores.cashierExpress.init();
    void this.sendCartLoadedBi();
    await this.updateComponent();
    this.isControllerLoaded = true;
  };

  private async sendCartLoadedBi() {
    return this.services.biService.viewCartPageSf({
      cartLayout: this.getCartLayout(),
      estimatedTotals: this.services.cartService.estimatedTotals,
      cartModel: this.services.cartService.cartModel,
      cartType: this.services.cartService.cartType,
      paymentMethods: (await this.services.storeMetaDataService.get())?.activePaymentMethods || [],
      numOfVisibleShippingOptions: Math.min(this.services.orderService.shippingRuleOptions.length, 2),
      shouldShowCoupon: this.services.styleSettingsService.shouldShowCoupon,
      shouldShowBuyerNote: this.services.styleSettingsService.shouldShowBuyerNote,
      shouldShowContinueShopping: this.services.styleSettingsService.shouldRenderContinueShopping,
      shouldShowShipping: this.services.styleSettingsService.shouldShowShipping,
      shouldShowTax: this.services.styleSettingsService.shouldShowTax,
      hasPickupOption: this.services.orderService.hasPickupOption,
      isCheckoutButtonPresented: this.services.styleSettingsService.isCheckoutButtonShowInSomeBreakpoint,
      isViewCartButtonPresented: this.services.styleSettingsService.isViewCartButtonShownInSomeBreakpoint,
    });
  }

  private readonly executeWithLoader = async (fn: Function) => {
    this.services.cartService.isSummaryUpdating = true;
    await this.controllerApi.updateComponent();

    await fn();
    await this.load();
  };

  public readonly onStyleUpdate = async (styleParams: {}): Promise<void> => {
    this.services.styleSettingsService.update(
      applyDefaults(this.getDefaultSettings(), styleParams as unknown as ICartStyleSettings)
    );
    await this.updateComponent();
  };

  private subscribeToChanges() {
    this.currentCartService.onChange(async () => {
      this.services.cartService.isSummaryUpdating = true;
      await this.controllerApi.updateComponent();
      void this.updateCurrentCart();
    });
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    this.siteStore.siteApis.onInstanceChanged(/* istanbul ignore next */ () => this.init(), APP_DEFINITION_ID);
  }

  private async updateCurrentCart() {
    this.services.cartService.clearLoadingItems();
    await this.services.cartService.fetchCartAndEstimateTotals();
    return this.updateComponent();
  }

  private setServices(controllerParams: ControllerParams) {
    const purchaseFlowPropertiesService = (controllerParams.appData.context as EcomPlatformViewerScriptContext)
      .purchaseFlowPropertiesService;
    this.services.modalManagerService = new ModalManagerService({siteStore: this.siteStore}, controllerParams);
    this.services.styleSettingsService = new StyleSettingsService(
      controllerParams,
      this.getDefaultSettings(),
      this.siteStore
    );
    this.services.biService = new BIService({siteStore: this.siteStore, origin: this.getOrigin()});
    this.services.cartService = new CartService({
      controllerApi: this.controllerApi,
      siteStore: this.siteStore,
      biService: this.services.biService,
      styleSettingsService: this.services.styleSettingsService,
      currentCartService: this.currentCartService,
      origin: this.getOrigin(),
    });
    this.services.productsService = new ProductsService({siteStore: this.siteStore});
    this.services.orderService = new OrderService({
      cartService: this.services.cartService,
      styleSettingsService: this.services.styleSettingsService,
      siteStore: this.siteStore,
    });
    this.services.minimumOrderAmountService = new MinimumOrderAmountService({
      orderService: this.services.orderService,
      styleSettingsService: this.services.styleSettingsService,
    });

    const mergedPublicData = {...this.publicData.APP, ...this.publicData.COMPONENT};
    this.services.multilingualService = new MultilingualService(
      this.siteStore,
      mergedPublicData,
      {} // todo: fetch appSettings and pass here
    );

    this.services.storeMetaDataService = new StoreMetaDataService({
      siteStore: this.siteStore,
      purchaseFlowPropertiesService,
    });
    this.services.checkoutNavigationService = new CheckoutNavigationService({
      siteStore: this.siteStore,
      origin: this.getOrigin(),
    });
    this.services.navigationService = new NavigationService({
      siteStore: this.siteStore,
      biService: this.services.biService,
      cartService: this.services.cartService,
      publicData: this.publicData,
      controllerApi: this.controllerApi,
      modalManagerService: this.services.modalManagerService,
      controllerParams,
    });
  }

  private setStores(controllerParams: ControllerParams) {
    this.stores.cart = new CartStore(this.flowAPI, this.controllerApi, this.siteStore, this.services, this.veloInputs);
    this.stores.order = new OrderStore(this.controllerApi, this.siteStore, this.services);
    this.stores.navigation = new NavigationStore(this.controllerApi, this.siteStore, this.services, {
      origin: this.getOrigin(),
    });
    this.stores.paymentMethod = new PaymentMethodStore(this.services);
    this.stores.cashierExpress = new CashierExpressStore(
      controllerParams,
      this.controllerApi,
      this.siteStore,
      this.services,
      this.services.styleSettingsService
    );
  }
}
